.Recent
{
    font-family: Garamond, 'Times New Roman', Times, serif;
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center; 
}

.Recent-text
{
    font-family: Garamond, 'Times New Roman', Times, serif;
    text-align: justify;
    width:500px;
}

.Recent-text h1
{
    text-align: center;
}

.Recent-text h2
{
    text-align: center;
}

#vtz
{
    text-align: center;
}

.Writings
{
    font-family: "lmroman10-regular";
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center; 

}
.Writings-text {
    width:595px;
    display: inline-block;
    font-size: 18px;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
}


.LinkAndSummary {
    width:560px;
    font-size: 20px;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    background-color : rgb(255, 255, 255);
    padding : 0;
    margin : 0;
    line-height : 20px;
}

#Alpha {
    font-size: 32px;
    width: 600px;
    text-align: center;
}

#p1 {
    padding : 0;
    margin : 0;
    line-height : 20px;
}
#myh1 {
    font-size: 24px;
    width: 600px;
    text-align: left;
    line-height : 23px;

}
#p2 {
    padding : 0;
    margin : 0;
    line-height : 20px;
    font-size: 16px;
}