body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Blankenburg';
  src: local('Blankenburg'), url(./fonts/Blankenburg.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoCondensedLight';
  src: local('RobotoCondensedLight'), url(./fonts/RobotoCondensed-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'LM-regular';
  src: local('LM-regular'), url('./fonts/LM-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'lmroman7-regular';
  src: local('lmroman7-regular'), url('./fonts/lmroman7-regular.otf') format('opentype');
}

@font-face {
  font-family: 'lmroman17-regular';
  src: local('lmroman17-regular'), url('./fonts/lmroman17-regular.otf') format('opentype');
}

@font-face {
  font-family: 'lmroman12-regular';
  src: local('lmroman12-regular'), url('./fonts/lmroman12-regular.otf') format('opentype');
}

@font-face {
  font-family: 'lmroman10-regular';
  src: local('lmroman10-regular'), url('./fonts/lmroman10-regular.otf') format('opentype');
}

@font-face {
  font-family: 'Latin Modern';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('https://latex.now.sh/style.css/fonts/LM-regular.woff2') format('woff2'),
    url('https://latex.now.sh/style.css/fonts/LM-regular.woff') format('woff'),
    url('https://latex.now.sh/style.css/fonts/LM-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Latin Modern';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('https://latex.now.sh/style.css/fonts/LM-italic.woff2') format('woff2'),
    url('https://latex.now.sh/style.css/fonts/LM-italic.woff') format('woff'),
    url('https://latex.now.sh/style.css/fonts/LM-italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Latin Modern';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('https://latex.now.sh/style.css/fonts/LM-bold.woff2') format('woff2'),
    url('https://latex.now.sh/style.css/fonts/LM-bold.woff') format('woff'),
    url('https://latex.now.sh/style.css/fonts/LM-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Latin Modern';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('https://latex.now.sh/style.css/fonts/LM-bold-italic.woff2') format('woff2'),
    url('https://latex.now.sh/style.css/fonts/LM-bold-italic.woff') format('woff'),
    url('https://latex.now.sh/style.css/fonts/LM-bold-italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Libertinus';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('https://latex.now.sh/style.css/fonts/Libertinus-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Libertinus';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('https://latex.now.sh/style.css/fonts/Libertinus-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Libertinus';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('https://latex.now.sh/style.css/fonts/Libertinus-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Libertinus';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('https://latex.now.sh/style.css/fonts/Libertinus-bold-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Libertinus';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('https://latex.now.sh/style.css/fonts/Libertinus-semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'Libertinus';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('https://latex.now.sh/style.css/fonts/Libertinus-semibold-italic.woff2') format('woff2');
}