/* Pay.css */
.pay-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45vh;
    background-color: #ffffff; /* White background */
  }
  
  .pay-content {
    text-align: center;
    border-radius: 8px;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    background-color: #6b8bcf; /* Keeping the blue for the content box */
  }
  
  .pay-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #ffffff; /* White text for contrast */
  }
  
  .pay-description {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #ffffff; /* White text for contrast */
  }
  
  .pay-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #4a6fa5;
    background-color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px; 
    transition: background-color 0.3s ease;
  }
  
  .pay-button:hover {
    background-color: #e0e0e0; /* Light grey for hover effect */
  }
  
  /* Custom styling for Stripe CardElement */
.StripeElement {
  background-color: white; /* White background for the card element */
  padding: 10px 14px;
  border-radius: 4px;
  border: 1px solid #ccc; /* Light grey border */
  box-shadow: 0 1px 3px 0 #e6ebf1; /* Subtle shadow for depth */
  transition: box-shadow 150ms ease;
  margin-bottom: 20px; 

  /* Style tweaks for better appearance */
  color: #32325d; /* Dark text for better readability */
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; /* Slightly darker shadow when focused */
}

.StripeElement--invalid {
  border-color: #fa755a; /* Red border for invalid input */
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; /* Background for autofilled content */
}

.select-course {
  margin-bottom: 20px; /* Adds space below the dropdown */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}