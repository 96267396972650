.top
{
    width: 100%;
    height: 50px;
    background-color: #efeaef; /*was eee*/
    top: 0; 
    display: flex;
    align-items: center;
    font-family: "RobotoCondensedLight";
}

.topLeft
{
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
}

.topCenter
{
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
}

.top2
{
    width: 100%;
    height: 50px;
    background-color: #efeaef;
    top: 0; 
    display: flex;
    align-items: center;
    font-family: "RobotoCondensedLight";
}

.topCenter2
{
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
}
.topList
{
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.topList a
{
    text-decoration: none; 
    color: black;
}

.topListItem
{
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
}

.square-button {
    width: 50px;       /* Square size */
    height: 50px;      /* Square size */
    background-color: #4CAF50; /* Button color */
    color: white;      /* Text color */
    border: none;
    font-size: 24px;   /* Size of the '+' sign */
    cursor: pointer;
    text-align: center;
    line-height: 50px; /* Vertically center the '+' sign */
    display: inline-block;
  }
  
  .square-button:hover {
    background-color: #45a049; /* Slightly darker shade on hover */
  }
  
  .plain-href {
    color: white;      /* Ensuring the link color is white */
    text-decoration: none; /* Remove underline from the link */
  }
  